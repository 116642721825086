import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Layout from "../components/layout";
import Persona from "../components/sections/persona";
import Seo from "../components/seo";

import "./company.scss";

const Company = () => {
  return (
    <Layout activeMenu="company">
      <Seo title="Unternehmen"></Seo>
      <section className="container">
        <div className="section-title">
          <h2>Unternehmen</h2>
        </div>
        <div className="image" style={{ textAlign: "center" }}>
          <StaticImage height={450} src={"../images/jautzke_shop.png"} />
        </div>
        <div className="company-text">
          Der Meisterbetrieb Jautzke Augenoptik existiert seit Oktober 2005 in
          Berlin Buckow. Wir sind ein traditionelles Augenoptikergeschäft und
          der Kunde steht dabei im Mittelpunkt. <br />
          <br />
          Unsere Mission ist Sie optimal zu Beraten bis Sie am Ende ihre
          perfekte Sehhilfe haben und somit problemlos den Alltag meistern
          können. <br />
          Dabei steht Ihnen ein kompetentes Team zur Seite.
        </div>
        <div className="section-title">
          <h2>Das Team</h2>
        </div>
        <div className="employees">
          <Persona
            image={<StaticImage src="../images/employees/001_square.jpeg" />}
            name="Michael Jautzke"
            position="Augenoptikermeister & Inhaber"
            description="Explicabo voluptatem mollitia et repellat qui dolorum quasi"
          ></Persona>
        </div>
        <div className="employees">
          <Persona
            image={<StaticImage src="../images/employees/002_square.jpeg" />}
            name="Christopher Aufmhoff"
            position="Augenoptikergeselle"
            description="Quisquam facilis cum velit laborum corrupti fuga rerum quia"
          ></Persona>
          <Persona
            image={<StaticImage src="../images/employees/004_square.jpeg" />}
            name="Simone Schulz"
            position="Augenoptikergesellin"
            description="Aut maiores voluptates amet et quis praesentium qui senda para"
          ></Persona>
          <Persona
            image={<StaticImage src="../images/employees/003_square.jpeg" />}
            name="Renate Jautzke"
            position="Büro & Servicekraft"
            description="Dolorum tempora officiis odit laborum officiis et et accusamus"
          ></Persona>
        </div>
      </section>
    </Layout>
  );
};

export default Company;
