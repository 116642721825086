import * as React from "react";

import "./persona.scss";

const Persona = ({ image, name, position, description }) => {
  return (
    <div
      className="persona-item"
      data-sal="zoom-in"
      data-sal-duration="500"
      data-sal-easing="ease"
    >
      <div className="image">{image}</div>
      <div className="content">
        <div className="name">{name}</div>
        <div className="position">{position}</div>
        <div className="divider"></div>
        {/* <div className="description">{description}</div> */}
      </div>
    </div>
  );
};

export default Persona;
